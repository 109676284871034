<template>
	<el-dialog :close-on-click-modal="false" title="生成量表二维码" width="40%" center :visible.sync="qrBoxVisible"  
		:destroy-on-close="true" @close="onClose">
		<div class="qr">
			<div class="qr-show">
				<h2>二维码(可查看结果)</h2>
				<el-collapse-transition>
					<div id="qrcode" ref="qrcode"></div>
				</el-collapse-transition>
				<el-button type="primary" @click="expiretimeselect1" style="margin-top: 10px;">生成</el-button>
			</div>
			<div class="qr-hide">
				<h2>二维码(不可查看结果)</h2>
				<el-collapse-transition>
					<div id="qrcode2" ref="qrcode2"></div>
				</el-collapse-transition>
				<el-button type="primary" @click="expiretimeselect2" style="margin-top: 10px;">生成</el-button>
			</div>
		</div>
		<div class="departmenselet">
			所属部门：
			<el-select v-model="departmenid" placeholder="请选择" size="mini" @change="departmenselect">
				<el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.id">
					{{ item.name }}
				</el-option>
			</el-select>
		</div>
		<div style="margin-top:20px">
			使用数量：
			<el-input type="number" size="mini" v-model="num" class="input-number" placeholder="不限"
				style="width: 206px;height: 10px;line-height: 10px"></el-input>
		</div>
		<div style="margin-top: 20px">
			过期日期：
			<el-date-picker size="mini" v-model="expiretime" type="datetime" placeholder="选择过期日期时间"
				default-time="12:00:00" value-format="timestamp">
			</el-date-picker>
		</div>
		<div style="margin-top: 20px">
			下载说明：请点击 - 鼠标右键- 选择 - 图片另存为
		</div>
		<!-- <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onClose">关 闭</el-button>
		</span> -->
	</el-dialog>
</template>

<script>
	import QRCode from "qrcodejs2";
	import { getMeasureCode } from "@/api/evaluate.js";
	
	export default {
		data(){
			return{
				departmenid:"",
				departmenName:"",
				num:null,
				expiretime:null,
				qrBoxVisible:false
			}
		},
		props:{
			qrBox:{
				type: Boolean,
				default () {
					return false;
				},
			},
			departmentList:{
				type: Array,
				default () {
					return [];
				},
			},
			measure:{
				type: Array,
				default () {
					return [];
				},
			}
		},
		watch:{
			qrBox:function(){
				this.qrBoxVisible = this.qrBox
			}
		},
		methods:{
			clearQrcode(type) {
				this.$nextTick(() => {
					if(type === 1){
						this.$refs.qrcode.textContent = "";
					}else{
						this.$refs.qrcode2.textContent = "";
					}
				});
			},
			departmenselect(values) {// 二维码，部门选择
				this.$props.departmentList.forEach((v) => {
					if (v.id == values) {
						this.departmenName = v.name;
						return;
					}
				});
			},
			async expiretimeselect1(){ // 可查看结果
				this.clearQrcode(1);
				let config = JSON.parse(localStorage.getItem("config"));
				let url = config.localhost;
				const expire = this.expiretime / 1000;
				let obj = {
					id: this.$props.measure.join(','),
					expire,
					num: this.num || '999999'
				};
				const res = await getMeasureCode(obj);
				if(res.code == 400200){
					this.$message.success("二维码生成成功");
					let host = `${location.origin}/index.html#/answer_content/answer_list?meaids=`;
					this.$nextTick(() => {
						let qrcode = new QRCode("qrcode", {
							width: 200, // 设置宽度，单位像素
							height: 200, // 设置高度，单位像素
							text:host + `${res.data}&meaidsshow=1&depId=${this.departmenid}&depName=${this.departmenName}&expire=${expire}` 
						});
					});
				}
			},
			async expiretimeselect2(){ // 可查看结果
				this.clearQrcode(2);
				let config = JSON.parse(localStorage.getItem("config"));
				let url = config.localhost;
				const expire = this.expiretime / 1000;
				let obj = {
					id: this.$props.measure.join(','),
					expire,
					num: this.num || '999999'
				};
				const res = await getMeasureCode(obj);
				if(res.code == 400200){
					this.$message.success("二维码生成成功");
					let host = `${location.origin}/index.html#/answer_content/answer_list?meaids=`;
					this.$nextTick(() => {
						let qrcode2 = new QRCode("qrcode2", {
							width: 200, // 设置宽度，单位像素
							height: 200, // 设置高度，单位像素
							text:host + `${res.data}&meaidsshow=0&depId=${this.departmenid}&depName=${this.departmenName}&expire=${expire}` 
						});
					});
				}
			},
			onClose(){
				this.$nextTick(() => {
					this.$refs.qrcode.textContent = "";
					this.$refs.qrcode2.textContent = "";
				});
				this.departmenid = "";
				this.departmenName = "";
				this.num = null;
				this.expiretime = null;
				this.$emit('onClose');
			}
		}
	}
</script>

<style lang="less" scoped>
	.qr {
		display: flex;
		justify-content: space-around;
		text-align: center;
	
		h2 {
			margin-bottom: 10px;
			font-size: 16px;
			cursor: pointer;
		}
	
		.qr-show {
			color: green;
		}
	
		.qr-hide {
			color: red;
		}
	}
	
	// 模态框
	.el-dialog__wrapper {
		.msgLoad {
			text-align: center;
			font-size: 18px;
			font-weight: 700;
		}
	
		.measure-msg {
			color: black;
		}
	
		.queryTable {
			max-height: 500px;
			overflow: auto;
	
			table {
				width: 100%;
				transition: all 0.25s;
	
				&:hover {
					background: rgb(236, 235, 211);
				}
			}
	
			.queryTable-title {
				font-weight: 700;
				font-size: 14px;
				color: #044d5a;
			}
	
			.queryTable-item {
				margin-left: 30px;
				color: rgb(48, 47, 47);
			}
		}
	}
	#qrcode2,#qrcode{
		width: 200px;
		height: 200px;
	}
</style>